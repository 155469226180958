import { useCallback, useEffect, useMemo, useState } from "react"
import { TrackerState, TrackingContext } from "~/contexts/tracking"
import { SnowplowClientFromEnv } from "./snowplow"
import { BroadcastTracker } from "./broadcast"
import useAuth from "~/hooks/use-authn"
import { getLogger } from "~/utils/logging"
import { useRouter } from "next/router"

// define a central logger for this file
const logger = getLogger("TRACKER")

/**
 * constructs a BroadcastTracker with all child trackers
 * @returns configured BroadcastTracker
 */
function buildTracker(): BroadcastTracker {
  logger.debug("building broadcast tracker")
  const tracker = new BroadcastTracker()
  // create and register a snowplow client
  // TODO: had to try/catch this b/c static page generation fails otherwise...
  try {
    tracker.addClients(SnowplowClientFromEnv())
  } catch (error) {
    logger.debug(`failed to add snowplow client, ${error}`)
  }
  return tracker
}

/**
 * TrackingProvider implements the TrackerState context to send usage data
 * to various remote trackers (like GoogleAnalytics, Snowplow, etc)
 * @param param0
 * @returns
 */
const TrackingProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // builds a broadcast tracker and adds clients
  const tracker = useMemo<BroadcastTracker>(buildTracker, [])
  // locally track the user as to not wastefully log in/out
  const [trackedUser, setTrackedUser] = useState<string>("")
  // get the router
  const router = useRouter()
  // read from our auth context to get the current user
  const authState = useAuth()

  // implement the render tracking
  const trackCustom = useCallback((category: string, action: string, label?: string, description?: string) => {
    logger.debug(`emitting custom event`, category, action, label, description)
    tracker.emitCustomEvent(category, action, label, description)
  }, [tracker])

  // login method
  const login = useCallback((userId: string) => {
    if (userId != trackedUser) {
      logger.debug(`logging in user ${userId}`)
      tracker.login(userId)
      setTrackedUser(userId)
    }
  }, [trackedUser, tracker])

  // logout method
  const logout = useCallback(() => {
    if (trackedUser != "") {
      logger.debug("logging user out")
      tracker.logout()
      setTrackedUser("")
    }
  }, [trackedUser, tracker])

  // subscribe to user login changes and log in/out in the tracker
  useEffect(() => {
    if (authState.userID) {
      login(authState.userID)
    } else {
      logout()
    }
  }, [authState.userID, login, logout])

  // emit page events when the path changes
  useEffect(() => {
    logger.debug(`current path "${router.asPath}"`)
    tracker.emitPageView(router.asPath)
  }, [router.asPath, trackedUser, tracker])

  // instantiate the TrackerState for the provider/context
  const trackerState: TrackerState = {
    track: trackCustom,
  }

  return (
    <TrackingContext.Provider value={trackerState}>
      {children}
    </TrackingContext.Provider>
  )
}

export default TrackingProvider
