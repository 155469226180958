import { getLogger } from "~/utils/logging"

// name of the cookie
export const COOKIE_PREFIX = 'reheat'
export const COOKIE_AUTHN_PREFIX = `${COOKIE_PREFIX}_authn`
export const COOKIE_AUTHN_TOKENS = `${COOKIE_AUTHN_PREFIX}_tokens`

// token version
const TOKEN_V1 = "v1"

export interface Tokens {
  accessToken: string
  refreshToken: string
  exp: Date
  userID: string
}

interface TokenCookieWrapper {
  version: string
  payload: Tokens
}

export function DeserializeToken(text: string): Tokens {
  const wrapper = JSON.parse(text)
  const token = wrapper.payload
  return {
    ...token,
    exp: new Date(token.exp),
  }
}

export function SerializeToken(token: Tokens): string {
  const wrapped: TokenCookieWrapper = {
    payload: token,
    version: TOKEN_V1
  }
  return JSON.stringify(wrapped)
}

export function FromAuthnApi(payload: any): Tokens {
  const nowMs = Date.now()
  return {
    accessToken: payload.access_token,
    refreshToken: payload.refresh_token,
    userID: payload.user_id,
    exp: new Date(nowMs + payload.ttl_ms)
  }
}
