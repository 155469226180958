import { gql } from "@apollo/client";

export const GQL_LIST_RECIPES = gql`
  query ListRecipes($pageToken: String, $pageSize: Int!, $userID: ID!, $isAuthenticated: Boolean!) {
    recipes(request:{pageToken:$pageToken, pageSize: $pageSize, sortBy:CREATED_AT_DESC}) {
      recipes {
        recipeId
        urlSlug
        createdAt
        title
        description
        defaultImageUrl
        createdBy {
          userId
          username
        }
        remixedFrom{
          urlSlug
          title
          defaultImageUrl
        }
        voteScore
        countVotes
        userVote(userID:$userID) @include(if: $isAuthenticated)
      }
      nextPageToken
    }
  }
`

export const GQL_SEARCH_RECIPES = gql`
  query SearchRecipes($text: String!, $pageSize: Int!) {
    recipes(request:{pageSize:$pageSize, sortBy: CREATED_AT_DESC, filter:{title:$text}}) {
      recipes {
        urlSlug
        title
        defaultImageUrl
        voteScore
        countVotes
      }
    }
  }
`

export const GQL_GET_RECIPE = gql`
  query GetRecipe($id: ID!, $isAuthenticated: Boolean!, $userID: ID!) {
    recipe(request: {id: $id}) {
      recipeId
      urlSlug
      createdAt
      createdBy {
        userId
        username
      }
      title
      description
      defaultImageUrl
      video {
        videoId
        videoUrl
        platform
      }
      ingredients
      instructions
      preparationTime
      cookingTime
      servings
      status
      remixedFrom{
        urlSlug
        title
        defaultImageUrl
      }
      remixRequest
      voteScore
      countVotes
      userVote(userID: $userID) @include(if: $isAuthenticated)
    }
  }
`

export const GQL_REMIX_RECIPE = gql`
  mutation RemixRecipe($id: ID!, $prompt: String!) {
    remixRecipe(request: {remixedFromRecipeId: $id, remixRequest: $prompt}) {
      recipeJobId
      status
    }
  }
`

export const GQL_GENERATE_RECIPE = gql`
  mutation generateRecipe($name:String!) {
    generateRecipe(request:{newRecipeName:$name}) {
      recipeJobId
      status
    }
  }
`

export const GQL_GENERATE_RECIPE_FROM_VIDEO = gql`
  mutation generateRecipeFromVideo($videoUrl:String!) {
    generateRecipeFromVideo(request: {videoUrl:$videoUrl}){
      recipeJobId
      status
    }
  }
`

export const GQL_GET_RECIPE_JOB = gql`
  query getRecipeJob($id: ID!) {
    recipeJob(request: {id: $id}) {
      recipeJobId
      status
      recipe {
        recipeId
      }
      failedReason
    }
  }
`

export const GQL_LIST_RECIPE_REMIXES = gql`
  query ListRecipeRemixes($pageToken: String, $pageSize: Int!, $recipeIds: [ID!], $isAuthenticated: Boolean!, $userID: ID!) {
    recipes(request:{pageToken:$pageToken, pageSize: $pageSize, sortBy:CREATED_AT_DESC, filter: {remixedFromRecipeIds: $recipeIds}}) {
      recipes {
        recipeId
        urlSlug
        createdAt
        title
        description
        defaultImageUrl
        createdBy {
          userId
          username
        }
        remixedFrom{
          urlSlug
          title
          defaultImageUrl
        }
        voteScore
        countVotes
        userVote(userID: $userID) @include(if: $isAuthenticated)
      }
      nextPageToken
    }
  }
`

export const GQL_MUTATION_UPVOTE_RECIPE = gql`
  mutation UpvoteRecipe($id: ID!) {
    upvote(request: { votableId: $id, votableType: RECIPE })
  }
`

export const GQL_MUTATION_DOWNVOTE_RECIPE = gql`
  mutation DownvoteRecipe($id: ID!) {
    downvote(request: { votableId: $id, votableType: RECIPE })
  }
`

export const GQL_MUTATION_UNVOTE_RECIPE = gql`
  mutation UnvoteRecipe($id: ID!) {
    unvote(request: { votableId: $id, votableType: RECIPE })
  }
`

export const GQL_RECIPE_CARD_FRAGMENT = gql`
  fragment RecipeCardParts on Recipe {
    recipeId
    urlSlug
    title
    defaultImageUrl
    createdBy {
      username
    }
    voteScore
    countVotes
    remixedFrom {
      defaultImageUrl
    }
  }
`

export const GQL_RECIPES_LLM = gql`
  query SearchRecipesLLM($queryString: String!, $numResults: Int!, $isAuthenticated: Boolean!, $userID: ID!) {
    recipesLLM(request:{queryString: $queryString, numResults: $numResults}) {
      recipes {
        recipeId
        urlSlug
        createdAt
        title
        description
        defaultImageUrl
        createdBy {
          userId
          username
        }
        remixedFrom{
          urlSlug
          title
          defaultImageUrl
        }
        voteScore
        countVotes
        userVote(userID: $userID) @include(if: $isAuthenticated)
      }
    }
  }
`
