import { TrackerClient } from "./client"

/**
 * BroadcastTracker sends tracking events to many child trackers
 */
export class BroadcastTracker implements TrackerClient {
  clients: TrackerClient[]

  /**
   * builds a BroadcastTracker with provided clients
   * @param clients
   */
  constructor(...clients: TrackerClient[]) {
    this.clients = []
    this.addClients(...clients)
  }

  /**
   * adds one or more TrackerClients to the broadcaster
   * @param clients one or more tracker clients
   */
  addClients(...clients: TrackerClient[]) {
    this.clients.push(...clients)
  }

  /**
   * emits page views on all provided clients
   * @param page
   */
  emitPageView(page: string): void {
    this.clients.forEach((client) => {
      client.emitPageView(page)
    })
  }

  /**
   * emit custom event on all provided clients
   * @param category
   * @param action
   * @param label
   * @param description
   */
  emitCustomEvent(category: string, action: string, label?: string, description?: string): void {
    this.clients.forEach((client) => {
      client.emitCustomEvent(category, action, label, description)
    })
  }

  /**
   * login across all clients
   * @param userId the user to log in
   */
  login(userId: string) {
    this.clients.forEach((client) => client.login(userId))
  }

  /**
   * log out across all clients
   */
  logout() {
    this.clients.forEach((client) => client.logout())
  }
}
