import { createGlobalStyle } from "styled-components"
import { mediaQueriesRanged } from "./breakpoints"

export default createGlobalStyle`
html, body {
  padding: 0;
  margin: 0;
  /* https://www.sitepoint.com/understanding-and-using-rem-units-in-css/ */
  // font-size: 62.5%; // this makes it so that 1rem ~ 10px;
  height: 100%;
  width: 100%;
  min-height: -webkit-fill-available;
  // background-color: black;

  @media ${mediaQueriesRanged.xs} {
    .hide-xs {
      display: none !important;
      visibility: hidden;
    }
  }
  @media ${mediaQueriesRanged.sm} {
    .hide-sm {
      display: none !important;
      visibility: hidden;
    }
  }
  @media ${mediaQueriesRanged.md} {
    .hide-md {
      display: none !important;
      visibility: hidden;
    }
  }
  @media ${mediaQueriesRanged.lg} {
    .hide-lg {
      display: none !important;
      visibility: hidden;
    }
  }
  @media ${mediaQueriesRanged.xl} {
    .hide-xl {
      display: none !important;
      visibility: hidden;
    }
  }
}
`
