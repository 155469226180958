import styled, { useTheme } from "styled-components"
import Header from "../header"
import { mediaQueriesRanged } from "~/styles/breakpoints"

interface Props {
  children: any
}

// Layout implements a layout for all child components and provides
// some common components across many pages
const Layout = (props: Props) => {
  const theme = useTheme()
  const bgColor: string = !!theme ? theme.colors.powderWhite : "white"

  return (
    <LayoutContainer>
      <Header />
      <Content
        bgColor={bgColor}
      >
        <Grid>
          {props.children}
        </Grid>
      </Content>
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  min-height: 100vh;
  height: 100vh;
  background-color: white; // make this the same as the header to prevent scrolling blackspots
  max-height: 100vh;
`

export default Layout

interface ContentProps {
  headerDisabled?: boolean
  bgColor: string
  page404?: boolean
  isSticky?: boolean
  scrollPageDisabled?: boolean
}

const Content = styled.div<ContentProps>`
  background-size: cover;
  position: relative;

  // header disabled properties
  top: ${(props) => (props.headerDisabled ? "0rem" : "4rem")};
  height: unset;
  // header disabled properties

  /* this needs to be the full height so that the hideaway menu doesn't screw with the top header  */
  min-height: ${(props) => (props.page404 ? "calc(100% - 14.2rem)" : "calc(100% - 4rem)")};
  background-color: ${(props) => props.bgColor};
  overflow-y: ${(props) => (props.isSticky ? "" : "auto")};
  overflow-x: ${(props) => (props.isSticky ? "" : "hidden")};
`

export const Grid = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;

  // xs
  grid-template-columns: repeat(1, 1fr);
  padding-top: 2rem;
  padding-bottom: 0rem;

  @media ${mediaQueriesRanged.sm} {
    grid-template-columns:
    [page-start] 20px
    repeat(2,[col-start] minmax(0,1fr) [col-end] 20px)
    [page-end];
  }
  @media ${mediaQueriesRanged.md} {
    grid-template-columns:
      [page-start] 30px
      repeat(4,[col-start] minmax(0,1fr) [col-end] 30px)
      [page-end];
  }
  @media ${mediaQueriesRanged.lg} {
    grid-template-columns:
      [page-start] 60px
      repeat(7,[col-start] minmax(0,1fr) [col-end] 40px)
      [col-start] minmax(0,1fr) [col-end] 60px
      [page-end];
    grid-template-rows: auto auto 1fr;
  }
  @media ${mediaQueriesRanged.xl} {
    grid-template-columns:
      [page-start] minmax(0, 1fr)
      repeat(7, [col-start] 110px [col-end] 40px)
      [col-start] 110px
      [col-end] minmax(0, 1fr)
      [page-end];

    margin: auto;
    max-width: 160rem;
  }
`
