import { useRouter } from "next/router"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { AuthenticationContext, AuthenticationState, AuthenticationStatus } from "~/contexts/authn"
import { getLogger } from "~/utils/logging"

const logger = getLogger("AuthenticationProvider")

/**
 * Props for the AuthProvider component, adding just a children which must be a JSX.
 */
interface AuthProviderProps {
  children: React.ReactNode
}

interface LocalState {
  userID: string
  status: AuthenticationStatus
  accessToken: string
}

const DEFAULT_LOCAL_STATE: LocalState = {
  status: AuthenticationStatus.BOOTING,
  userID: "",
  accessToken: "",
}

const LOGGED_OUT_STATE: LocalState = {
  status: AuthenticationStatus.ANONYMOUS,
  userID: "",
  accessToken: "",
}

const publicRoutes: Set<string> = new Set([
  '/sign-in',
  '/onboarding',
  '/collections',
  '/collections/[slug]',
  '/404',
  '/recipes',
  '/recipes/[slug]',
  '/u/[id]'
])

function isPublicRoute(route: string): boolean {
  return publicRoutes.has(route)
}

const { BOOTING, LOGGED_IN, ANONYMOUS } = AuthenticationStatus

const AuthenticationProvider: FC<AuthProviderProps> = ({ children }) => {

  const [authState, setAuthState] = useState<LocalState>(DEFAULT_LOCAL_STATE);

  const router = useRouter();

  useEffect(() => {
    logger.debug(`changed pathName`, router.pathname)
    if (authState.status === ANONYMOUS && !isPublicRoute(router.pathname)) {
      logger.debug(`redirecting unauthenticated visitor to sign in`)
      router.push('/sign-in')
    }
  }, [router.pathname, authState.status, router])

  const logout = useCallback(() => {
    logger.debug(`begin logout`)
    fetch("/api/authn/logout")
    .then((res) => {
      return res.ok ? Promise.resolve() : Promise.reject("failed to log out")
    })
    .then(() => {
      setAuthState(LOGGED_OUT_STATE)
    })
    .then(() => logger.debug("logged out"))
    .catch((reason) => {
      logger.error(`logout failed`, reason)
    })
  }, [])

  const check = useCallback(() => {
    fetch("/api/authn/check")
      .then((res) => {
        return res.ok ? Promise.resolve(res.json()) : Promise.reject("failed")
      })
      .then((res) => {
        logger.debug(`result`, res)
        const accessToken: string = !!res.accessToken ? res.accessToken : ""
        setAuthState({
          userID: res.userID,
          status: res.isAuthenticated ? LOGGED_IN : ANONYMOUS,
          accessToken: res.isAuthenticated ? accessToken : ""
        })
      })
      .catch((reason) => {
        logger.error(`check failed`, reason)
      })
  }, [])

  useEffect(() => {
    if (authState.status === BOOTING) {
      logger.debug(`booting`)
      check()
    }
  }, [authState.status, check])

  useEffect(() => {
    logger.debug(`state="${AuthenticationStatus[authState.status]}", userID="${authState.userID}"`)
  }, [authState])

  // create the state for authn context
  const contextState: AuthenticationState = useMemo(() => ({
    userID: authState.userID,
    status: authState.status,
    accessToken: authState.accessToken,
    logout: logout,
    check: check,
  }), [authState.accessToken, authState.status, authState.userID, check, logout])

  return (
    <AuthenticationContext.Provider value={contextState}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
