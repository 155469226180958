import { gql } from "@apollo/client";
import { GQL_RECIPE_CARD_FRAGMENT } from "./recipes";

export const GQL_GET_USER_BY_ID = gql`
  query GetUser($id:ID!, $isPrivate: Boolean!) {
    user(request:{id:$id}) {
      userId
      username
      email @include(if: $isPrivate)
      status @include(if: $isPrivate)
    }
  }
`

export const GQL_UPDATE_USER = gql`
  mutation UpdateUser($id:ID!, $username: String!) {
    updateUser(request: {userId: $id, username: $username}) {
      userId
      status
    }
  }
`

export const GQL_GET_USER_BY_USERNAME = gql`
  ${GQL_RECIPE_CARD_FRAGMENT}
  query GetUser($username:String!, $isPrivate: Boolean!) {
    user(request:{username:$username}) {
      userId
      username
      email @include(if: $isPrivate)
      status @include(if: $isPrivate)
      userSummaryStats {
        totalVoteScore
      }
      newRecipes: createdRecipes(request:{pageToken:"", pageSize:10, sortBy:CREATED_AT_DESC}) {
        recipes {
          ...RecipeCardParts
        }
        nextPageToken
      }
      topRecipes: createdRecipes(request:{pageToken:"", pageSize:10, sortBy:VOTE_SCORE_DESC}) {
        recipes {
          ...RecipeCardParts
        }
        nextPageToken
      }
      newRemixes: createdRecipes(request:{pageToken:"", pageSize:10, sortBy:CREATED_AT_DESC}) {
        recipes {
          ...RecipeCardParts
        }
        nextPageToken
      }
      topRemixes: directRemixRecipes(request:{pageToken:"",pageSize: 10, sortBy:VOTE_SCORE_DESC}) {
        recipes {
          ...RecipeCardParts
        }
        nextPageToken
      }
    }
  }
`
