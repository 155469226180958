import { GetServerSidePropsContext, PreviewData } from "next"
import { ParsedUrlQuery } from "querystring"
import { COOKIE_AUTHN_TOKENS, DeserializeToken, Tokens } from "~/pages/api/authn/helpers"

type Context = GetServerSidePropsContext<ParsedUrlQuery, PreviewData>

function getSsrTokens(context: Context): Tokens | undefined {
  const tokenText: string | undefined = context.req.cookies[COOKIE_AUTHN_TOKENS]
  if (!tokenText) {
    return
  }
  return DeserializeToken(tokenText)
}

/**
 * extracts access token from next.js ssr conext
 * @param context next.js ssr context
 * @returns access token or empty string
 */
export function getSsrAccessToken(context: Context): string {
  const tokenText: string | undefined = context.req.cookies[COOKIE_AUTHN_TOKENS]
  if (!tokenText) {
    return ""
  }
  const tokens = DeserializeToken(tokenText)
  return tokens.accessToken
}

export function getSsrRefreshToken(context: Context): string {
  const tokens = getSsrTokens(context);
  if (!tokens) {
    return ""
  }
  return tokens.refreshToken
}

/**
 * extracts user ID from next.js ssr conext
 * @param context next.js ssr context
 * @returns userID or empty string
 */
export function getSsrUserID(context: Context): string {
  const tokenText: string | undefined = context.req.cookies[COOKIE_AUTHN_TOKENS]
  if (!tokenText) {
    return ""
  }
  const tokens = DeserializeToken(tokenText)
  return tokens.userID
}


/**
 * isAuthenticatedSsr returns true when context is authenticated
 * @param context next.js ssr context
 * @returns true if authenticated
 */
export function isAuthenticatedSsr(context: Context): boolean {
  const accessToken = getSsrAccessToken(context)
  return !!accessToken
}

/**
 * returns true if tokens are missing or expired
 * @param context next.js ssr context
 * @returns true if needs refresh, false if active
 */
export function isExpiredSsr(context: Context): boolean {
  const tokens = getSsrTokens(context);
  if (!tokens) {
    return true
  }
  // return true // todo: remove
  return tokens.exp < new Date()
}
