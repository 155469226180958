import { ApolloClient, useApolloClient } from "@apollo/client"
import { useRouter } from "next/router"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { AuthenticationStatus } from "~/contexts/authn"
import { UserContext, UserState, UserStatus } from "~/contexts/user"
import { GQL_GET_USER_BY_ID } from "~/graphql/user"
import useAuthN from "~/hooks/use-authn"
import { handleApolloError } from "~/utils/gql"
import { getLogger } from "~/utils/logging"

const logger = getLogger("UserProvider");

interface UserProviderProps {
  children: React.ReactNode
}

interface LocalState {
  status: UserStatus
}

const BOOTING_STATE: LocalState = {
  status: UserStatus.BOOTING
}

const GUEST_STATE: LocalState = {
  status: UserStatus.GUEST
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
  const authn = useAuthN();
  const client = useApolloClient();
  const [ localState, setLocalState ] = useState<LocalState>(BOOTING_STATE)
  const router = useRouter();

  useEffect(() => {
    if (authn.status === AuthenticationStatus.BOOTING) {
      setLocalState(BOOTING_STATE)
    }
    else if (authn.status === AuthenticationStatus.ANONYMOUS) {
      setLocalState(GUEST_STATE)
    }
    else if (authn.status === AuthenticationStatus.LOGGED_IN && !!authn.userID) {
      getUser(client, authn.userID)
      .then((user) => {
        const isOnboarding: boolean = user.status === "ONBOARDING"
        setLocalState({
          status: isOnboarding ? UserStatus.ONBOARDING : UserStatus.READY,
        })
      })
    }
  }, [authn.status, authn.userID, client])

  const check = useCallback(() => {
    logger.debug(`checking onboarding user`)
    getUser(client, authn.userID).then((user) => {
      logger.debug()
      const isOnboarding: boolean = user.status === "ONBOARDING"
      if (!isOnboarding) {
        setLocalState({
          status: UserStatus.READY
        })
      }
    })
  }, [authn.userID, client])

  // for onboarding, poll every 3 seconds
  useEffect(() => {
    if (localState.status === UserStatus.ONBOARDING) {
      const i = setInterval(() => {
        check()
      }, 3000)
      return () => clearInterval(i)
    }
  }, [check, localState.status])

  useEffect(() => {
    const currentPath = router.pathname
    const onboardingPath = "/onboarding"
    const isOnboardig = localState.status === UserStatus.ONBOARDING
    const isAtOnboarding = currentPath === onboardingPath
    if (router.isReady && isOnboardig && !isAtOnboarding) {
      // TODO: remember where they were!
      router.push(onboardingPath)
    }
  }, [localState.status, router])

  const contextState: UserState = useMemo(() => ({
    status: localState.status,
    check: check,
  }), [check, localState.status])

  return (
    <UserContext.Provider value={contextState}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider

interface LocalUser {
  username: string,
  email: string,
  status: string,
}

function getUser(client: ApolloClient<any>, userID: string): Promise<LocalUser> {
  return client.query({
    query: GQL_GET_USER_BY_ID,
    variables: { id: userID, isPrivate: true },
    fetchPolicy: "no-cache",
  })
  .then(handleApolloError)
  .then((res) => {
    logger.debug(`received user`, res.data.user)
    const user = res.data.user
    const output: LocalUser = {
      email: user.email,
      username: user.username,
      status: user.status,
    }
    return output
  })
}
