import { createContext } from "react"

// AuthenticationStatus defines the possible states of user authentication
export enum AuthenticationStatus {
  BOOTING,
  LOGGED_IN,
  ANONYMOUS,
}

// AuthenticationState defines an interface for the data in the AuthContext
export interface AuthenticationState {
  userID: string
  accessToken: string
  status: AuthenticationStatus
  logout: VoidFunction
  check: VoidFunction
}

export const DefaultAuthenticationState: AuthenticationState = {
  userID: "",
  accessToken: "",
  status: AuthenticationStatus.BOOTING,
  logout: () => {},
  check: () => {},
}

// AuthContext exposes the AuthState to downstream code
export const AuthenticationContext = createContext({} as AuthenticationState)
