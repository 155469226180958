import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { AuthenticationStatus } from "~/contexts/authn";
import useAuthN from "~/hooks/use-authn";
import RecipeSearchInput from "../recipe-search-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faClose } from "@fortawesome/free-solid-svg-icons";
import { mediaQueriesRanged } from "~/styles/breakpoints";
import { getLogger } from "~/utils/logging";
import Link from "next/link";

const logger = getLogger("Header");

interface HeaderProps {}

const Header = (props: HeaderProps) => {
  const router = useRouter();
  const { status, logout } = useAuthN();

  const isLoggedIn: boolean = useMemo(() => {
    return status === AuthenticationStatus.LOGGED_IN;
  }, [status]);

  const handleLogOut = useCallback(() => {
    logout();
  }, [logout]);

  const handleLogIn = useCallback(() => {
    router.push("/sign-in");
  }, [router]);

  const authButton = useMemo(() => {
    if (isLoggedIn) {
      return <button onClick={handleLogOut}>log out</button>;
    }
    return <button onClick={handleLogIn}>log in</button>;
  }, [handleLogIn, handleLogOut, isLoggedIn]);

  const [searchModalOpen, setSearchModalOpen] = useState<boolean>(false);

  const openSearchModal = () => {
    logger.debug(`open search modal`);
    setSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    logger.debug(`close search modal`)
    setSearchModalOpen(false);
  };

  const search = useMemo(() => {
    return (
      <SearchContainer>
        <RecipeSearchInput />
      </SearchContainer>
    );
  }, []);

  const searchMobile = useMemo(() => {
    return (
      <IconWrapper>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          onClick={openSearchModal}
          width={18}
        />
      </IconWrapper>
    );
  }, []);

  const bigHeader = useMemo(() => {
    return (
      <InnerContainer className="hide-xs hide-sm">
        <EdgeBox>
          <HeaderText>
            <Link href={'/'}>
              reheat
            </Link>
          </HeaderText>
        </EdgeBox>
        {search}
        <EdgeBox $textAlign={"right"}>{authButton}</EdgeBox>
      </InnerContainer>
    );
  }, [authButton, search]);

  useEffect(() => {
    logger.debug(`modal open`, searchModalOpen);
  }, [searchModalOpen]);

  // close modal on escape key
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
       if (event.key === 'Escape') {
        closeSearchModal()
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const searchModal = useMemo(() => {
    return (
      <ModalContainer open={searchModalOpen}>
        <MobileSearchContainer>
          <ModalLayout>
            <div className="search-box">
              <RecipeSearchInput onComplete={closeSearchModal} />
            </div>
            <div className="exit-icon-box">
              <FontAwesomeIcon
                size="sm"
                icon={faClose}
                onClick={closeSearchModal}
                width={16}
              />
            </div>
          </ModalLayout>
        </MobileSearchContainer>
      </ModalContainer>
    );
  }, [searchModalOpen]);

  const smallHeader = useMemo(() => {
    return (
      <InnerContainer className="hide-md hide-lg hide-xl">
        <EdgeBox>{searchMobile}</EdgeBox>
        <MobileHeaderText>
          <Link href='/'>
            reheat
          </Link>
        </MobileHeaderText>
        <EdgeBox $textAlign={"right"}>{authButton}</EdgeBox>
        {searchModal}
      </InnerContainer>
    );
  }, [authButton, searchMobile, searchModal]);

  return (
    <Container {...props}>
      {bigHeader}
      {smallHeader}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.colors.powderWhite};
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.02);
  z-index: 101;
  position: fixed;
  align-items: center;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const HeaderText = styled.h1`
  font-size: 100;
  color: #000000;
  fontstyle: normal;
  margin: 0;

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
`;

const MobileHeaderText = styled.h1`
  font-size: 100;
  color: #000000;
  fontstyle: normal;
  margin: 0;
  text-align: center;

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }
`;

const EdgeBox = styled.div<{ $textAlign?: "left" | "right" | "center" }>`
  width: 100px;
  height: 100%
  align-items: center;
  text-align: ${(props) => (props.$textAlign ? props.$textAlign : "left")};

  @media ${mediaQueriesRanged.xs} {
    width: 50px;
  }

  @media ${mediaQueriesRanged.sm} {
    width: 50px;
  }
`;

const SearchContainer = styled.div`
  width: calc(100vw - 600px); // look into this
  min-width: 400px;
  height: calc(100% - 25px);
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;
`;

const ModalContainer = styled.div<{ open?: boolean }>`
  display: ${(props) => (!!props.open ? "block" : "none")};
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.powderWhite};
`;

const ModalLayout = styled.div`
  width: 100%;
  height: 100%;
  align-items: top;
  display: flex;
  justify-text: center;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
`;

const MobileSearchContainer = styled.div`
  width: 100%;
  height: 2.6rem;
  align-items: top;
  display: flex;
  flex-direction: row;
  z-index: 100;

  .search-box {
    width: calc(100% - 40px);
  }

  .exit-icon-box {
    width: 20px;
    align-items: center;
    display: flex;
  }
`;

const IconWrapper = styled.div`
  width: 17px;
  text-align: inherit;
`;

export default Header;
