import { ApolloProvider } from "@apollo/client";
import { FC, useEffect, useMemo } from "react";
import { AuthenticationStatus } from "~/contexts/authn";
import useAuthN from "~/hooks/use-authn";
import { ApolloApiClient } from "~/lib/apollo-wrapper";
import { makeClientWrapper } from "~/lib/apollo-wrapper/make-client";
import { getLogger } from "~/utils/logging";

const logger = getLogger("ApolloProviderWrapper")

interface ApolloProvderWrapperProps {
  children: React.ReactNode
}

const ApolloProviderWrapper: FC<ApolloProvderWrapperProps> = ({ children }) => {
  const authn = useAuthN();
  const client: ApolloApiClient = useMemo(() => {
    logger.debug('creating client')
    return makeClientWrapper()
  }, [])

  useEffect(() => {
    switch (authn.status) {
      case AuthenticationStatus.ANONYMOUS:
        client.clearAccessToken()
        break;
      case AuthenticationStatus.LOGGED_IN:
        client.setAccessToken(authn.accessToken)
      default:
        logger.debug(`waiting for definitive status`)
    }
  }, [authn, client])

  return (
    <ApolloProvider client={client.client}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloProviderWrapper
