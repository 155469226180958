import { createContext } from "react"

/**
 * TrackerState provides methods for tracking app activities (like page loads
 * and component renders) and emits data to tracking providers
 */
export interface TrackerState {
  track(category: string, action: string, label?: string, description?: string): void
}

/**
 * defaultTrackerState implements no-ops for all TrackerState methods
 */
const defaultTrackerState: TrackerState = {
  track: () => {}
}

const TrackingContext = createContext({} as TrackerState)

export { TrackingContext, defaultTrackerState }
