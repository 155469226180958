import { ApolloClient, ApolloLink, DefaultOptions, InMemoryCache, createHttpLink } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import getConfig from "next/config";
import { getLogger } from "~/utils/logging";

const logger = getLogger("ApolloApiClient")

const { publicRuntimeConfig } = getConfig();

// https://www.apollographql.com/docs/react/errors/#%7B%22version%22%3A%223.8.0-beta.3%22%2C%22message%22%3A14%2C%22args%22%3A%5B%5D%7D
if (publicRuntimeConfig.isDev) {  // Adds messages only in a dev environment
  logger.debug("loading apollo dev logging")
  loadDevMessages();
  loadErrorMessages();
}

export class ApolloApiClient {
  private accessToken: string
  // fully qualified url/route for graphql calls
  private queryUrl: string

  public client: typeof ApolloClient.prototype

  /**
   * @returns {ApolloApiClient}
   */
  constructor(queryUrl: string) {
    this.accessToken = ""
    this.queryUrl = queryUrl;
    this.client = this.buildClient()

    logger.debug(`new client, queryURL="${this.queryUrl}"`)
  }

  setAccessToken(token: string) {
    logger.debug(`setting access token`)
    this.accessToken = token
  }

  clearAccessToken() {
    logger.debug(`clearing access token`)
    this.accessToken = ""
  }

  /**
   * @returns {ApolloClient} - instance
   */
  buildClient() {
    const childLinks = []
    // add the links we understand
    childLinks.push(this.authLink())
    childLinks.push(this.clientErrorLink())
    childLinks.push(this.clientHttpLink())
    // blend them together like a re-heated recipe baby
    const links = ApolloLink.from(childLinks)

    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }

    return new ApolloClient({
      ssrMode: typeof window === "undefined",
      link: links,
      cache: this.clientCache(),
      defaultOptions: defaultOptions,
      credentials: "include",
    })
  }

  /**
   *
   * @returns {InMemoryCache} - cache for apolloClient
   */
  clientCache() {
    return new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {},
        },
      }
    })
  }

  /**
   * clientErrorLink
   * Error Link for apollo
   * @returns {ApolloLink} - base http link
   */
  clientErrorLink() {
    const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      }
    })
    return errorLink
  }

  /**
   * @returns {ApolloLink} - base http link
   */
  clientHttpLink() {
    return createHttpLink({uri: this.queryUrl})
  }

  /**
   * @returns {ApolloLink} - header context to be built w/ result of clientHttpLink
   */
  authLink() {
    return setContext((_, { headers }) => {
      if (this.accessToken) {
        return {
          headers: { ...headers, authorization: `Bearer ${this.accessToken}` },
        }
      }
      return { headers: { ...headers } }
    })
  }
}
