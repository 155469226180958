import { isServer } from "~/utils/browser";
import { ApolloApiClient } from "./client";

import getConfig from "next/config";
import { ApolloClient } from "@apollo/client";
import { GetServerSidePropsContext, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";
import { getLogger } from "~/utils/logging";
import { getSsrAccessToken } from "./tokens";

const logger = getLogger("make-client")

export function makeClientWrapper(): ApolloApiClient {
  // read config
  const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
  const { apiHostInternal } = serverRuntimeConfig;
  const { apiHost } = publicRuntimeConfig;
  // build url
  const host = isServer() ? apiHostInternal : apiHost;
  const queryUrl: string = `${host}/graph/v1`;
  // build client
  return new ApolloApiClient(queryUrl);
}

type Context = GetServerSidePropsContext<ParsedUrlQuery, PreviewData>

export function makeClientSsr(ctx: Context): ApolloClient<any> {
  const clientWrapper = makeClientWrapper();
  const accessToken = getSsrAccessToken(ctx);
  if (!!accessToken) {
    clientWrapper.setAccessToken(accessToken)
  }
  return clientWrapper.client;
}
