import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components"
import { getLogger } from "~/utils/logging";
import { useApolloClient } from "@apollo/client";
import { GQL_GENERATE_RECIPE, GQL_GENERATE_RECIPE_FROM_VIDEO, GQL_SEARCH_RECIPES } from "~/graphql/recipes";
import { handleApolloError } from "~/utils/gql";
import debounce from "lodash.debounce"
import Link from "next/link";
import { useRouter } from "next/router";
import { isDescendant } from "~/utils/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import useAuthN from "~/hooks/use-authn";
import { AuthenticationStatus } from "~/contexts/authn";

interface SearchProps {
  onComplete?: VoidFunction
}

interface SearchState {
  text: string,
  isSearching: boolean,
  results: SearchResult[],
}

const DefaultSearchState: SearchState = {
  text: "",
  isSearching: false,
  results: [],
}

interface SearchResult {
  urlSlug: string
  title: string
}

const logger = getLogger("RecipeSearchInput")

const RecipeSearchInput = (props: SearchProps) => {
  const [searchState, setSearchState] = useState<SearchState>(DefaultSearchState);
  const client = useApolloClient();
  const router = useRouter();
  const authn = useAuthN();

  const doSearch = useCallback(
    debounce((text: string) => {
      logger.debug(`searching`, text)
      client.query({
        query: GQL_SEARCH_RECIPES,
        variables: {
          text: text,
          pageSize: 10,
        }
      })
        .then(handleApolloError)
        .then((res) => {
          setSearchState((prior) => {
            if ((!prior.text)) {
              return prior
            }
            const recipes: SearchResult[] = res.data.recipes.recipes
              .map((r: any) => ({
                urlSlug: r.urlSlug,
                title: r.title,
              }))
            return { ...prior, results: recipes, isSearching: true }
          })
        })
        .catch((err) => {
          logger.error(`failed to search!`, JSON.stringify(err))
        })
    }, 300),
    [client],
  )

  const doGenerate: (prompt: string) => Promise<string> = useCallback((prompt) => {
    logger.debug(`begin doGenerate, prompt="${prompt}"`)

    return client.mutate({
      mutation: GQL_GENERATE_RECIPE,
      variables: { name: prompt }
    })
      .then(res => {
        logger.debug(`completed`, res)
        if (!!res.errors?.length) {
          logger.error(`generate failed`, res.errors)
          return Promise.reject(res.errors)
        }
        // try to extract the ID
        const jobID: string = res.data?.generateRecipe?.recipeJobId || ""
        if (!jobID) {
          logger.error("malformed response")
          return Promise.reject()
        }
        // return the job ID
        return Promise.resolve(jobID)
      })
  }, [client])

  const doGenerateFromVideo: (videoUrl: string) => Promise<string> = useCallback((videoUrl) => {
    logger.debug(`begin doGenerateFromVideo, videoUrl="${videoUrl}"`)

    return client.mutate({
      mutation: GQL_GENERATE_RECIPE_FROM_VIDEO,
      variables: { videoUrl: videoUrl }
    })
      .then(res => {
        logger.debug(`completed`, res)
        if (!!res.errors?.length) {
          logger.error(`generate from video failed`, res.errors)
          return Promise.reject(res.errors)
        }
        // try to extract the ID
        const jobID: string = res.data?.generateRecipeFromVideo?.recipeJobId || ""
        if (!jobID) {
          logger.error("malformed response")
          return Promise.reject()
        }
        // return the job ID
        return Promise.resolve(jobID)
      })
  }, [client])

  const updateTextInput = useCallback((event: any) => {
    setSearchState((prior) => {
      return { ...prior, text: event.target.value }
    })
  }, [])

  useEffect(() => {
    if (!!searchState.text) {
      doSearch(searchState.text)
    } else {
      setSearchState(DefaultSearchState)
    }
  }, [doSearch, searchState.text])

  const generateButton = useMemo(() => {
    let displayText: string = `🪄 ${searchState.text}`;
    let callback: (event: React.MouseEvent<HTMLDivElement>) => void;
    let isValidUrl: boolean = true;

    try {
        new URL(searchState.text);
    } catch (_) {
        isValidUrl = false;
    }

    if (authn.status !== AuthenticationStatus.LOGGED_IN) {
      callback = () => {
        logger.debug('clicked generate without an authn token present');
        // todo: add url args here to redirect back after login
        router.push(`/sign-in`)
      }
    } else if (isValidUrl && (searchState.text.startsWith('https://www.youtube.com') || searchState.text.startsWith('https://youtu.be'))) {
      displayText = `🪄 Make Recipe from Video`;

      callback = () => {
        logger.debug(`clicked generate from video callback!`);
        doGenerateFromVideo(searchState.text)
          .then((jobID) => router.push(`/mixing/${jobID}`))
          .then(() => !!props.onComplete && props.onComplete());
      }
    } else {
      callback = () => {
        logger.debug(`clicked generate callback!`);
        doGenerate(searchState.text)
          .then((jobID) => router.push(`/mixing/${jobID}`))
          .then(() => !!props.onComplete && props.onComplete());
      }
    }

    const handleFocus = (e: any) => {
      logger.debug(`focus generate`);
    };

    return (
      <GenerateItem
        tabIndex={-1}
        key={`generate`}
        onClick={callback}
        onFocus={handleFocus}
      >
        {displayText}
      </GenerateItem>
    );
  }, [authn.status, doGenerate, doGenerateFromVideo, props, router, searchState.text]);

  const collectionButton = useMemo(() => {

    const handleFocus = (e: any) => {
      logger.debug(`focus generate menu`)
    }

    let callback: (event: React.MouseEvent<HTMLDivElement>) => void;

    if (authn.status !== AuthenticationStatus.LOGGED_IN) {
      callback = () => {
        logger.debug('clicked generate menu without an authn token present');
        // todo: add url args here to redirect back after login
        router.push(`/sign-in`)
      }
    } else {
      callback = () => {
        logger.debug(`clicked generate menu callback!`);
        // todo
        router.push({
          pathname: `/menu`,
          query: {
            search: searchState.text,
          },
        }).then(() => {
          !!props.onComplete && props.onComplete();
        });
      }
    }

    return (
      <GenerateCollctionItem
        tabIndex={-2}
        key={`generate-menu`}
        onClick={callback}
        onFocus={handleFocus}
      >
        👨‍🍳 generate a menu
      </GenerateCollctionItem>
    )
  }, [authn.status, router, searchState.text])

  const suggestions = useMemo(() => {
    const elems: JSX.Element[] = searchState.results.map((r) => (
      <Link key={r.urlSlug} href={`/recipes/${r.urlSlug}`}>
        <SuggestionItem
          onClick={() => !!props.onComplete && props.onComplete()}
          key={r.urlSlug}>
          {r.title}
        </SuggestionItem>
      </Link>
    ))
    if (!!searchState.text) {
      elems.push(generateButton)
      elems.push(collectionButton)
    }

    if (!elems.length) {
      return <></>
    }

    return (
      <SuggestionsBox>
        <div className="results">
          {elems}
        </div>
      </SuggestionsBox>
    )
  }, [collectionButton, generateButton, props, searchState.results, searchState.text])

  useEffect(() => {
    if (searchState.results.length > 0) {
      const results = searchState.results.map((r) => `[${r.title}]`).join(", ")
      logger.debug(`results`, results)
    }
  }, [searchState.results])

  const handleFocus = useCallback(() => {
    setSearchState((prior) => ({ ...prior, isSearching: true }))
  }, [])

  const handleBlur = useCallback((e: any) => {
    // e.stopPropagation();
    const currentTarget = e.currentTarget;
    requestAnimationFrame(() => {
      if (!isDescendant(currentTarget, document.activeElement)) {
        setSearchState((prior) => ({
          ...prior,
          isSearching: false
        }))
      }
    })
  }, [])

  useEffect(() => {
    logger.debug(`route changed`, router.asPath)
    setSearchState(DefaultSearchState)
  }, [router.asPath])

  return (
    <Container
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <SearchOutline tabIndex={-1} $isActive={searchState.isSearching}>
        <SearchIcon>
          <FontAwesomeIcon icon={faMagnifyingGlass} width={18} />
        </SearchIcon>
        <SearchInput
          value={searchState.text}
          onChange={updateTextInput}
          placeholder="Search"
        />
      </SearchOutline>

      {searchState.isSearching && suggestions}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SearchIcon = styled.div`
  width: 20px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
`

const SearchOutline = styled.div<{ $isActive?: boolean }>`
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  background-color: #f8f8f5;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;

  border: ${props => props.$isActive ? "1px solid black" : "none"};

  &:focus {
    box-shadow: 0px 0px 2px black;
  }

  &:hover {
    box-shadow: 0px 0px 2px black;
  }
`

const SearchInput = styled.input`
  width: 100%;
  height: calc(90% - 2px);
  background: transparent;
  border: none;
  font-size: 17px;

  &:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }

  &:hover {
    box-shadow: none;
    outline: none;
    border: none;
  }
`

const SuggestionsBox = styled.div`
width: 100%;

position: absolute;
top: 110%;
left: 0;
padding-top: 4px;

font-size: 17px;

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.results {
    min-height: 30px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 12px;
  }
`

const SuggestionItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: none;
  // border-bottom: 1px solid #d4d4d4;
  &:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
`

const GenerateItem = styled(SuggestionItem)`
  background-color: none;
  &:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
`

const GenerateCollctionItem = styled(SuggestionItem)`
  background-color: none;
  &:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
  }
`

export default RecipeSearchInput;
