import React from "react";
import { ThemeProvider } from "styled-components";
import 'styled-components';

interface StringToStringMap {
  [key: string]: string;
}

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: StringToStringMap
    fonts: string[]
    fontSizes: StringToStringMap
  }
}

const theme = {
  colors: {
    powderWhite: "#FFFDF9",
    upvoteGreen: "#50C878",
    downvoteRed: "#FF6D6A",
    selectedOrange: "#ff964f",
    highlightOrange: "#FAC898",
    onyx: "#36313D",
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

interface Props {
  children: any
}

const Theme: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
