import { Logger, ILogObj } from "tslog";

const logger: Logger<ILogObj> = new Logger();

export function getLogger(name: string): Logger<ILogObj> {
  return logger.getSubLogger({
    name: name,
    hideLogPositionForProduction: true
  })
}
