import { useContext } from "react"

import { AuthenticationContext, AuthenticationState } from "~/contexts/authn"

const useAuthN = (): AuthenticationState => {
  const context = useContext(AuthenticationContext)
  if (typeof context === "undefined") {
    throw new Error("useAuth must be used within an AuthenticationProvider")
  }
  return context
}

export default useAuthN
