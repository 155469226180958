import type { AppProps } from 'next/app'
import Layout from '~/components/layout'
import Theme from '~/components/theme'
import ApolloProviderWrapper from '~/providers/apollo'
import AuthenticationProvider from '~/providers/authn'
import TrackingProvider from '~/providers/tracking'
import UserProvider from '~/providers/user'
import Globals from '~/styles/globals'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
    <AuthenticationProvider>
      <TrackingProvider>
        <ApolloProviderWrapper>
          <UserProvider>
            <Theme>
              <Layout>
                <Globals />
                <Component {...pageProps} />
              </Layout>
            </Theme>
          </UserProvider>
        </ApolloProviderWrapper>
      </TrackingProvider>
    </AuthenticationProvider>
    </>
  )
}
