import { createContext } from "react"

// AuthenticationStatus defines the possible states of user authentication
export enum UserStatus {
  BOOTING,
  GUEST,
  ONBOARDING,
  READY
}

// AuthenticationState defines an interface for the data in the AuthContext
export interface UserState {
  status: UserStatus,
  check: VoidFunction,
}

export const DefaultUserState: UserState = {
  status: UserStatus.BOOTING,
  check: () => {},
}

// AuthContext exposes the AuthState to downstream code
export const UserContext = createContext({} as UserState)
