/**
 * helper method to determine if on the server
 * @returns true if on server, else false
 */
export function isServer(): boolean {
  return typeof window === "undefined"
}

/**
 * helper method to determine if running in browser
 * @returns true if browser, else false
 */
export function isBrowser(): boolean {
  return !isServer()
}

/**
 * returns true if child node is a descendant of the parent
 * @param parent some html node
 * @param child some html node
 * @returns true if child is withing parent
 */
export const isDescendant = (parent: Node, child: Node | null): boolean => {
  if (child === null) {
    return false
  }
  if (parent.isEqualNode(child.parentNode)) {
    return true
  }
  return isDescendant(parent, child.parentNode)
};
