
export const breakpoints: {[key: string]: number} = {
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1440,
}

const size = (sz: string) => `${breakpoints[sz]}px`

/**
 * mediaQueries
 *
 * sm: 350,
 *
 * md: 768,
 *
 * lg: 1024,
 *
 * xl: 1440,
 */
export const mediaQueries = {
  sm: `(min-width: ${size("sm")})`,
  md: `(min-width: ${size("md")})`,
  lg: `(min-width: ${size("lg")})`,
  xl: `(min-width: ${size("xl")})`,
}

export const mediaQueriesRanged = {
  xs: `(max-width: ${breakpoints.sm - 1}px)`,
  sm: `${mediaQueries.sm} and (max-width: ${breakpoints.md - 1}px)`,
  md: `${mediaQueries.md} and (max-width: ${breakpoints.lg - 1}px)`,
  lg: `${mediaQueries.lg} and (max-width: ${breakpoints.xl - 1}px)`,
  xl: `${mediaQueries.xl}`,
}
