import {
  clearUserData,
  ClearUserDataConfiguration,
  enableActivityTracking,
  newTracker,
  PageViewEvent,
  setUserId,
  TrackerConfiguration,
  trackPageView,
  trackStructEvent,
} from "@snowplow/browser-tracker"
import getConfig from "next/config"
import { getLogger } from "~/utils/logging"
import { TrackerClient } from "./client"
import { isServer } from "~/utils/browser"

// ------------------------------------------------
// CONSTANTS
const POST_PATH: string = '/sp/track'
const APP_ID: string = 'web'
const NAMESPACE: string = 'reheat'
// ------------------------------------------------


// define a central logger for this file
const logger = getLogger("SNOWPLOW")

/**
 * SnowplowClient provides a small wrapper around the snowplow API
 */
export class SnowplowClient implements TrackerClient {
  // set the namespace of this app in snowplow
  trackerNamespace: string = NAMESPACE
  // endpoint for tracking events
  // TODO: accept from constructor or global config
  trackerEndpoint: string
  // configuration
  // https://docs.snowplowanalytics.com/docs/collecting-data/collecting-from-own-applications/javascript-trackers/browser-tracker/browser-tracker-v3-reference/tracker-setup/initialization-options/
  trackerConfig: TrackerConfiguration = {
    appId: APP_ID,
    encodeBase64: true,
    discoverRootDomain: true,
    cookieSameSite: "Lax",
    contexts: {
      webPage: true,
      session: true,
    },
    postPath: POST_PATH,
  }

  /**
   * builds a snowplow client
   */
  constructor(trackerEndpoint: string) {
    logger.debug(`begin configuring at ${trackerEndpoint}`)
    this.trackerEndpoint = trackerEndpoint
    // configure global tracker
    newTracker(this.trackerNamespace, this.trackerEndpoint, this.trackerConfig)
    logger.debug("started tracker")
    // start continuous tracking of pages
    this.emitHeartbeats()
  }

  /**
   * emit page view data to snowplow
   * @param page
   */
  emitPageView(page: string): void {
    logger.debug(`emitPageView ${page}`)
    const event: PageViewEvent = {
      title: page,
    }
    trackPageView(event)
  }

  /**
   * emits custom event via snowplow "trackStructEvent"
   * https://github.com/snowplow/snowplow-javascript-tracker/blob/master/trackers/browser-tracker/docs/markdown/browser-tracker.trackstructevent.md
   * @param category The name you supply for the group of objects you want to track e.g. ‘media’, ‘ecomm’
   * @param action A string which defines the type of user interaction for the web object e.g. ‘play-video’, ‘add-to-basket’
   * @param label An optional string which identifies the specific object being actioned e.g. ID of the video being played, or the SKU or the product added-to-basket
   * @param description An optional string which further explains the action taking place for custom events
   */
  emitCustomEvent(category: string, action: string, label?: string, description?: string): void {
    logger.debug(`emitCustomEvent`)
    if (description) {
      trackStructEvent({
        category: category,
        action: action,
        label: label,
        property: description,
      })
    } else {
      trackStructEvent({
        category: category,
        action: action,
        label: label,
      })
    }
  }

  /**
   * logs a user into the snowplow tracker
   * @param userId the user to log in
   */
  login(userId: string) {
    setUserId(userId)
  }

  /**
   * implements logout on snowplow to remove the user ID from further events
   */
  logout() {
    const cfg: ClearUserDataConfiguration = {
      preserveSession: false,
      preserveUser: false,
    }
    clearUserData(cfg)
  }

  /**
   * continuously emit pings when on a page
   */
  private emitHeartbeats(): void {
    enableActivityTracking({
      // how long before the first event
      minimumVisitLength: 30,
      // how long between heartbeats
      heartbeatDelay: 15,
    })
  }
}


/**
 * Create a snowplow client from the Next.JS environment
 * @returns snowplow client
 */
export function SnowplowClientFromEnv(): SnowplowClient {
  // read next config
  const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()
  // conditionally return the server or client config
  // TODO: we REALLY need dependable variables here and a type
  // so we dont need to check for global config existence...
  let snowplowAddress: string = ""
  if (isServer()) {
    if (serverRuntimeConfig?.snowplowHost) {
      snowplowAddress = serverRuntimeConfig.snowplowHost
    }
  } else {
    if (publicRuntimeConfig?.snowplowHost) {
      snowplowAddress = publicRuntimeConfig.snowplowHost
    }
  }
  if (snowplowAddress.length == 0) {
    throw new Error("failed to configure snowplow, missing address")
  }
  return new SnowplowClient(snowplowAddress)
}
