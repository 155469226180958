import { ApolloQueryResult, FetchResult } from "@apollo/client";

/**
 * converts apollo errors to a failed promise
 * @param res an apollo query result
 * @returns promise with the result or failed with reason
 */
export function handleApolloError<U, T extends ApolloQueryResult<U>>(res: T): Promise<T> {
  if (!!res.error) {
    return Promise.reject(res.error)
  }
  if (!!res.errors?.length) {
    return Promise.reject(res.errors)
  }
  return Promise.resolve(res)
}
